<template>
  <v-container  class="pa-0">
    <v-card>
      <v-card-title>
        {{ this.param.name }}
        <v-spacer></v-spacer>
        <v-btn-toggle>
          <v-btn @click="resume_dialog = !resume_dialog">
            <v-icon>mdi-finance</v-icon>
          </v-btn>
          <v-btn @click="filter_dialog = !filter_dialog">
            <v-icon>mdi-filter-settings-outline</v-icon>
          </v-btn>
          <v-btn @click="openForm">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table :headers="headers[$vuetify.breakpoint.smAndDown]" :items="item_filtered" :items-per-page="10"
          mobile-breakpoint="0" calculate-widths class="table-cursor elevation-1 mt-3" :search="search"
          :loading="loading_status" loader-height="10" loading-text="Cargando ..." @click:row="handleClick"
          sort-by="issue_date" :sort-desc="true">
          <template v-slot:top>
            <v-text-field v-model="filtersA.location" label="Filtro" class="mx-4" @keyup="filterData" />
          </template>
          <template v-slot:[`item.description`]="{ item }">
            {{ item.description }} <br><small>{{ item.note }} </small>
          </template>
          <template v-slot:[`item.qty`]="{ item }">
            {{ item.qty + " " + item.uom }}

          </template>
          <template v-slot:[`item.ref_qty`]="{ item }">
            {{ item.ref_qty + " " + item.ref_uom }}

          </template>
          <template slot="body.append">
            <tr>
              <th>Total</th>
              <th v-if="!$vuetify.breakpoint.smAndDown"></th>
              <th></th>
              <th class="text-right ">{{ sumTable('item_filtered', 'qty') }}</th>
              <th class="text-right"></th>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card class="mt-3">
      <GChart type="ColumnChart" :data="issueDateChart" :options="chartOptions" />
    </v-card>

    <v-card class="mt-3">
      <GChart type="LineChart" :data="refDateChart" :options="chartOptions" />
    </v-card>


    <v-dialog v-model="filter_dialog" transition="dialog-fade-transition" scrollable max-width="480px"
      :fullscreen="$vuetify.breakpoint.smAndDown">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat width="100%">
            Filtro
            <v-spacer></v-spacer>
            <v-icon @click="filter_dialog = !filter_dialog"> mdi-close </v-icon>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="pt-3">
          <v-row>
            <v-col>
              <v-text-field v-model="dateFrom" label="Desde" hide-details="auto" required outlined type="date" />
            </v-col>
            <v-col>
              <v-text-field v-model="dateTo" label="Hasta" hide-details="auto" required outlined type="date" />
            </v-col>
            <v-col>
              <v-btn large color="success" block type="button" @click="get_items">Buscar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="add_dialog" transition="dialog-fade-transition" scrollable max-width="480px"
      :fullscreen="$vuetify.breakpoint.smAndDown">
      <v-form v-model="valid" ref="itemForm" @submit="put_data">
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar flat width="100%">
              {{ this.param.name }}
              <v-spacer></v-spacer>
              <v-icon @click="add_dialog = !add_dialog"> mdi-close </v-icon>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-row>
              <v-col cols="12" md="12">
                <v-select v-model="locationInfo" :items="locationSelected" label="Origen" item-value="code"
                  item-text="code" return-object @change="updateForm" hide-details="auto" outlined clearable>
                </v-select>
              </v-col>
              <v-col cols="12" md="12" v-if="this.traslado">
                <v-select v-model="locationTo" :items="locationSelected" label="Destino" item-value="code"
                  item-text="code" hide-details="auto" outlined clearable>
                </v-select>
              </v-col>

              <v-col cols="6" md="8" v-if="!this.note">
                <v-text-field v-model="rg.qty" :value="rg.qty" label="Biomasa" hide-details="auto" type="number"
                  outlined />
              </v-col>
              <v-col cols="6" md="4" v-if="!this.note">
                <v-select v-model="rg.uom" :items="uom_weigth" label="UOM" hide-details="auto" outlined />
              </v-col>
              <v-col cols="6" md="8" v-if="!this.note">
                <v-text-field v-model="rg.ref_qty" label="Cantidad" hide-details="auto" type="number" outlined />
              </v-col>
              <v-col cols="6" md="4" v-if="!this.note">
                <v-select v-model="rg.ref_uom" :items="uom_qty" label="UOM" hide-details="auto" outlined />
              </v-col>

              <v-col cols="12" md="12">
                <v-textarea v-model="rg.note" outlined label="Nota" rows="2">
                </v-textarea>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-btn large color="success" block type="submit">Registrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="resume_dialog" transition="dialog-fade-transition" scrollable max-width="600px"
      :fullscreen="$vuetify.breakpoint.smAndDown">
      <v-form v-model="valid" ref="itemForm" @submit="put_data">
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar flat width="100%">
              <v-spacer></v-spacer>
              <v-icon @click="resume_dialog = !resume_dialog"> mdi-close </v-icon>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-data-table :headers="headerReport[$vuetify.breakpoint.smAndDown]" :items="locationsFiltred"
              :items-per-page="10" ref="locationsFiltered" mobile-breakpoint="0" calculate-widths
              class="table-cursor elevation-1 mt-3" :search="searchresume" :loading="loading_status" loader-height="10"
              loading-text="Cargando ..." @click:row="handleClick" sort-by="issue_date" :sort-desc="true">
              <template v-slot:top>
                <v-text-field v-model="filters.tank" label="Filtro" class="mx-4" @keyup="filterDataResume">
                </v-text-field>
              </template>
              <template v-slot:[`item.size`]="{ item }">
                {{ item.size }} {{ item.size_uom }}
              </template>
              <template v-slot:[`item.description`]="{ item }">
                {{ item.description }} <br><small>{{ item.note }} </small>
              </template>
              <template v-slot:[`item.qty`]="{ item }">
                {{ parseFloat(item.qty).toFixed(2) + " " + item.uom }}

              </template>
              <template v-slot:[`item.ref_qty`]="{ item }">
                {{ parseFloat(item.ref_qty).toFixed(2) + " " + item.ref_uom }}

              </template>
              <template slot="body.append">
                <tr>
                  <th>Total</th>
                  <th class="text-right ">{{ sumTable('locationsFiltred', 'size') }}</th>
                  <th class="text-right ">{{ sumTable('locationsFiltred', 'qty') }}</th>
                  <th class="text-right ">{{ sumTable('locationsFiltred', 'ref_qty') }}</th>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>


  </v-container>
</template>
<script>
import { GChart } from "vue-google-charts";
import { webserver, getToday, getdays_ago } from "../../services/webserver.js";
export default {
  components: { GChart },
  mounted() {
    this.param = this.$route.params;
    this.rg.account = window.company.code;
    this.rg.code = this.param.code;
    this.rg.description = this.param.name;
    this.get_menu();
  },
  data: () => ({
    search: '',
    searchresume: '',
    loading_status: false,
    items: [],
    events: [],
    locations: [],
    locationSelected: [],
    headers: {
      false: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "issue_date",
          dataType: "text",
        },
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "description",
          dataType: "text",
        },
        {
          text: "loc",
          align: "start",
          sortable: true,
          value: "location",
          filterable: true,
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "qty",
          dataType: "text",
        },
        {
          text: "Ref",
          align: "end",
          sortable: true,
          value: "ref_qty",
          dataType: "text",
        },
      ],
      true: [

        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "description",
          dataType: "text",
        },
        {
          text: "loc",
          align: "start",
          sortable: true,
          value: "location",
          filterable: true,
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "qty",
          dataType: "text",
        },
        {
          text: "Ref",
          align: "end",
          sortable: true,
          value: "ref_qty",
          dataType: "text",
        },
      ],
    },
    headerReport: {
      false: [
        {
          text: "POF",
          align: "start",
          sortable: true,
          value: "code",
          dataType: "text",
        },
        {
          text: "Biomasa",
          align: "end",
          sortable: true,
          value: "qty",
          dataType: "text",
        },
        {
          text: "Ref",
          align: "end",
          sortable: true,
          value: "ref_qty",
          dataType: "text",
        },
        {
          text: "Tipo Alimento",
          align: "end",
          sortable: true,
          value: "food_type",
          dataType: "text",
        },
        {
          text: "Factor",
          align: "end",
          sortable: true,
          value: "factor",
          dataType: "number",
        },
        {
          text: "Nota",
          align: "start",
          sortable: true,
          value: "description",
          dataType: "text",
        },
      ],
      true: [

        {
          text: "POF",
          align: "start",
          sortable: true,
          value: "code",
          dataType: "text",
        },
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "size",
          dataType: "text",
        },

        {
          text: "Biomasa",
          align: "end",
          sortable: true,
          value: "qty",
          dataType: "text",
        },
        {
          text: "Cantidad",
          align: "end",
          sortable: true,
          value: "ref_qty",
          dataType: "text",
        },
        {
          text: "Tipo Alimento",
          align: "end",
          sortable: true,
          value: "food_type",
          dataType: "text",
        },
        {
          text: "Factor",
          align: "end",
          sortable: true,
          value: "factor",
          dataType: "number",
        },
        {
          text: "Nota",
          align: "start",
          sortable: true,
          value: "description",
          dataType: "text",
        },
      ],
    },
    report: [],
    event: null,
    dateFrom: getdays_ago(-7),
    dateTo: getToday(),
    filtersA: {
      location: null,
      event: null,
    },
    filters: {
      location: null,
      event: null,
    },
    item_filtered: [],
    param: { code: "", name: "" },
    filter_dialog: false,
    add_dialog: false,
    uom_weigth: ["KG", "GR"],
    uom_qty: ["UN"],
    rg: { account: null, code: null, description: '', location: '', qty: 0, uom: "KG", ref_qty: 0, ref_uom: "UN", note: "" },
    locationInfo: null,
    locationsFiltred: [],
    valid: false,
    resume_dialog: false,
    issueDateChart: [],
    refDateChart: [],
    chartOptions: {
      curveType: "function",
      legend: { position: "top" },
      interpolateNulls: true,
      crosshair: { trigger: "both", opacity: 0.95, color: "#39FF14" },
      height: 400,
      colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
      title: "",
      isStacked: false,
      hAxis: {
        title: "",
        slantedText: true,
        slantedTextAngle: 15,
      },
    },
    traslado: false,
    locationTo: null,
    note: false,
  }),
  methods: {
    updateSelected() {
      console.log(this.locationsFiltred)
    },
    handleClick(e) {
      console.log(e)
    },
    sumTable(table, key) {
      // sum data in give key (property)
      return parseFloat(this[table].reduce((a, b) => a + (parseFloat(b[key]) || 0), 0)).toLocaleString(2)
    },
    openForm() {

      console.log(this.param);
      switch (this.param.code) {
        case '00':
          this.locationSelected = this.locations.filter(o => o.tank === 'T1');
          break;
        case '02':
          this.locationSelected = this.locations;
          break;
        case '03':
          this.locationSelected = this.locations;
          this.traslado = true;
          break;
        case '06':
          this.locationSelected = this.locations;
          this.note = true;
          break;
        case '07':
          this.locationSelected = this.locations;
          this.note = true;
          break;
        case '08':
          this.locationSelected = this.locations;
          this.note = false;
          break;
        default:
          this.locationSelected = this.locations;

      }
      this.add_dialog = true;
      // add_dialog = !add_dialog
    },
    filterData() {
      var filter = this.filtersA;
      this.item_filtered = this.items.filter(function (item) {
        for (var key in filter) {
          if (filter[key] !== null && filter[key] !== "") {
            if (item[key] === undefined || item[key] != filter[key])
              if (item[key].search(filter[key])) return false;
          }
        }
        return true;
      });
      this.charts_update(this.item_filtered);
    },
    filterDataResume() {
      var filter = this.filters;
      this.locationsFiltred = this.locations.filter(function (item) {
        for (var key in filter) {
          if (filter[key] !== null && filter[key] !== "") {
            if (item[key] === undefined || item[key] != filter[key])
              return false;
          }
        }
        return true;
      });
    },
    get_events(e) {
      console.log(e);
      this.get_items();
    },
    get_items() {
      this.loading_status = true;

      var qry = {
        account: window.company.code,
        table: "fish_events",
        filters: [
          {
            field: "account",
            operator: "=",
            value: window.company.code,
          },
          {
            field: "date(issue_date)",
            operator: ">=",
            value: this.dateFrom,
          },
          {
            field: "date(issue_date)",
            operator: "<=",
            value: this.dateTo,
          }
        ],
      };
      if (this.param.code) {
        qry.filters.push(
          {
            field: 'code',
            operator: "=",
            value: this.param.code,
          }
        )
      }
      console.log('->', qry);
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        this.items = data;
        this.item_filtered = data;
        this.filterData();
        this.filter_dialog = false;
      });
    },
    get_menu() {
      this.loading_status = true;
      var qry = {
        account: window.company.code,
        table: "fish_docs",
        filters: [
          {
            field: "account",
            operator: "=",
            value: window.company.code,
          },
        ],
      };
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        this.events = data;
        this.get_locations();
      });
    },
    get_locations() {
      this.loading_status = true;
      var qry = {
        account: window.company.code,
        table: "fish_locations",
        filters: [
          {
            field: "account",
            operator: "=",
            value: window.company.code,
          },
        ],
      };
      webserver("get_table", qry, (data) => {
        this.loading_status = false;
        this.locations = data;
        this.locationsFiltred = data;
        this.get_items();
      });
    },
    updateForm(e) {
      console.log(e);
      if (e) {
        this.rg.location = e.code;
        switch (this.param.code) {
          case '00':
            var locti = this.locations.find(o => o.tank === 'T1');
            console.log(locti);
            break;
          case '01':
            // code block
            break;
          case '02':
            var biomasa = e.qty
            var food = (((biomasa * e.factor) / 100)/e.frecuency).toFixed(2);
            var note = "Biomasa: " + parseFloat(biomasa).toFixed(2) + ", factor: " + e.factor + "%, Alimento: " + e.food_type;
            // this.rg.location = e.code;
            this.rg.qty = food;
            this.rg.uom = e.uom;
            this.rg.ref_qty = e.ref_qty;
            this.rg.ref_uom = e.ref_uom;
            this.rg.note = note;
            break;
          default:
            this.rg.qty = parseFloat(e.qty).toFixed(2);
            this.rg.uom = e.uom;
            this.rg.ref_qty = parseFloat(e.ref_qty).toFixed(2);
            this.rg.ref_uom = e.ref_uom;
          // code block
        }
      } else {
        this.rg = { account: window.company.code, code: this.param.code, description: '', location: '', qty: 0, uom: "KG", ref_qty: 0, ref_uom: "UN", note: "" };
      }

    },
    put_data(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        var qry = {
          account: window.company.code,
          type: this.rg.code,
          data: this.rg,
          locationTo: this.locationTo,
        };
        console.log(qry);
        webserver("fish_rg", qry, () => {
          this.loading_status = false;
          this.add_dialog = false;
          this.get_locations();
          this.locationTo = null;
          this.rg = { account: null, code: null, description: '', location: '', qty: 0, uom: "KG", ref_qty: 0, ref_uom: "UN", note: "" };
          this.note = false;
        });
      }
    },
    charts_update(dta) {

      dta.forEach((itm) => {
        var issueD = new Date(itm.issue_date);
        var date = issueD.getDate();
        var month = issueD.getMonth();
        var year = issueD.getFullYear();
        itm.issueDate = year + "-" + (month + 1) + "-" + date;
      });

      var result = [];
      dta.reduce(function (res, value) {
        if (!res[value.issueDate]) {
          res[value.issueDate] = { label: value.issueDate, value: 0 };
          result.push(res[value.issueDate]);
        }
        res[value.issueDate].value += parseFloat(value.qty);
        return res;
      }, {});

      result.sort((a, b) =>
        a.value > b.value ? 1 : b.value > a.value ? -1 : 0
      );

      var dtSet = [];
      dtSet.push(["Fecha", "Biomasa"]);
      result.forEach((rg) => {
        var item = [];
        item.push(rg.label);
        item.push(rg.value);
        dtSet.push(item);
      });

      this.issueDateChart = dtSet;



      result = [];
      dta.reduce(function (res, value) {
        if (!res[value.issueDate]) {
          res[value.issueDate] = { label: value.issueDate, value: 0 };
          result.push(res[value.issueDate]);
        }
        res[value.issueDate].value += parseFloat(value.ref_qty);
        return res;
      }, {});

      result.sort((a, b) =>
        a.value > b.value ? 1 : b.value > a.value ? -1 : 0
      );

      dtSet = [];
      dtSet.push(["Fecha", "Peces"]);
      result.forEach((rg) => {
        var item = [];
        item.push(rg.label);
        item.push(rg.value);
        dtSet.push(item);
      });

      this.refDateChart = dtSet;

    },
  },
}
</script>
<style scoped>
.bg-texture {
  /* background: url("../../assets/trout.jpg") no-repeat center center fixed; */

  background: black;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}
</style>
